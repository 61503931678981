const browseSad = document.querySelector('.js-browse-sad')
const htmlElement = document.querySelector('html')
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

const checkCookie = name => {
  return document.cookie.match(
    '^(?:.*;)?\\s*' + name + '\\s*=\\s*([^;]+)(?:.*)?$'
  )
}

const browseHappyHandler = () => {
  if (isIE11 && !checkCookie('browsehappy')) {
    htmlElement.classList.add('-show-browse-happy')
    browseSadHandler()
  }
}

const browseSadHandler = () => {
  browseSad.addEventListener('click', e => {
    e.preventDefault()
    let date = new Date()
    date.setDate(date.getDate() + 1)
    htmlElement.classList.remove('-show-browse-happy')
    document.cookie = 'browsehappy=false; expires=' + date
  })
}

browseHappyHandler()
