const uA = navigator.userAgent
const vendor = navigator.vendor

if (
  /Safari/i.test(uA) &&
  /Apple Computer/.test(vendor) &&
  !/Mobi|Android/i.test(uA)
) {
  document.querySelector('html').classList.add('-is-safari')
}
