import enquire from 'enquire.js'
import { bp, mq, MQ } from 'js/utils/mq'

const $html = document.querySelector('html')
let currentBreakpoint
let isDesktop
let isTablet
let isMobile

const mobileEvent = new CustomEvent('view:mobile')
enquire.register(MQ(mq.xxxs, mq.m), {
  match() {
    currentBreakpoint = 'mobile'
    isMobile = true
    isTablet = false
    isDesktop = false

    $html.dispatchEvent(mobileEvent)
  },
})

const tabletEvent = new CustomEvent('view:tablet')
enquire.register(MQ(mq.m, mq.l), {
  match() {
    currentBreakpoint = 'tablet'
    isMobile = false
    isTablet = true
    isDesktop = false

    $html.dispatchEvent(tabletEvent)
  },
})

const desktopEvent = new CustomEvent('view:desktop')
enquire.register(MQ(mq.l), {
  match() {
    currentBreakpoint = 'desktop'
    isMobile = false
    isTablet = false
    isDesktop = true

    $html.dispatchEvent(desktopEvent)
  },
})

export { currentBreakpoint, isMobile, isTablet, isDesktop }
